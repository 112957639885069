import {
  useForm,
  SubmitHandler,
  FieldValues,
  DefaultValues,
} from "react-hook-form";
import { ZodSchema } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

type UseFormWithValidationProps<TFormSchema extends FieldValues> = {
  schema: ZodSchema<TFormSchema>;
  defaultValues?: DefaultValues<TFormSchema>;
  onSubmit: SubmitHandler<TFormSchema>;
};

function useFormWithValidation<TFormFields extends FieldValues>({
  schema,
  defaultValues,
  onSubmit,
}: UseFormWithValidationProps<TFormFields>) {
  const formMethods = useForm<TFormFields>({
    resolver: zodResolver(schema),
    defaultValues,
  });

  return { ...formMethods, onSubmit };
}

export default useFormWithValidation;
